import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Icon from "../Icons/Icon";
import styles from "./VideoTimer.module.scss";

const VideoTimer = (props) => {
    const {isVideoPlay, isEnded, duration, className, type} = props;

    const [currentTime, setCurrentTime] = useState(0);
    const [pauseTime, setPauseTime] = useState(0.000);

    useEffect(()=>{
        if(!duration) return;

        let timeleft = pauseTime > 0.000 ? pauseTime : Math.floor(duration);

        if(isVideoPlay) {
            const start = Date.now() + (timeleft * 1000);
            const timer = setInterval(() => {
                if(timeleft <= 0.000) {
                    clearInterval(timer);
                } else {
                    const current = Date.now();
                    timeleft = (start - current) / 1000;
                    setCurrentTime(timeleft);
                    setPauseTime(timeleft);
                }
            }, 10)
            return ()=> {
                clearInterval(timer);
            };
        }

        if(!isVideoPlay) {
            if(pauseTime > 0.000) {
                setCurrentTime(pauseTime);
            } else {
                setCurrentTime(duration);
            }
        }

    },[isVideoPlay, isEnded]);

    useEffect(() => {
        if(!duration) return;

        if (isEnded) {
            setPauseTime(0.000);
            setCurrentTime(duration);
        }
    },[isEnded]);

    function formatTime(timeInSeconds) {
        const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

        return {
            minutes: result.substr(3, 2),
            seconds: result.substr(6, 2),
        };
    }

    const renderTimer = () => {
        const time = formatTime(currentTime ? Math.round(currentTime) : Math.round(duration));

        return (
            <span>
                {`${time.minutes}:${time.seconds}`}
            </span>
        );
    }

    return (
        <span className={`${styles.time} ${type ? styles[type] : ''} ${className} font-bold`}>
            <span>
                <Icon name="Play2" width={8} height={10} color="white" />
            </span>
            {duration && renderTimer()}
        </span>
    );
}

VideoTimer.propTypes = {
    isVideoPlay: PropTypes.bool,
    isEnded: PropTypes.bool,
    duration: PropTypes.number,
    className: PropTypes.string,
    type: PropTypes.oneOf(['list', 'detail'])
};

export default VideoTimer;
