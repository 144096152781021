import React, { Component } from 'react';
import styles from '../MockupPreview.module.scss';
import hashString from '../../../../utils/hashString';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import LocalisedTitle from '../../../Base/LocalisedTitle/LocalisedTitle';

class MockupPreviewTitle extends Component {
    constructor() {
        super();
        this.state = {
            offset: 0,
            textOverflow: 'ellipsis'
        };
        this.wrapper = null;
        this.text = null;
    }

    handleMouseOver = () => {
        this.countTitleWidth();
    };

    handleMouseLeave = () => {
        this.setState({ offset: 0 });
        setTimeout(() => {
            if (this.wrapper) {
                this.setState({ textOverflow: 'ellipsis' });
            }
        }, 500);
    };

    countTitleWidth() {
        if (!this.wrapper || !this.text) return;

        const wrapperWidth = this.wrapper.getBoundingClientRect().width - 10; // padding;
        const textWidth = this.text.getBoundingClientRect().width;
        if (textWidth <= wrapperWidth) return;
        this.setState({ textOverflow: 'clip' });
        this.setState({ offset: wrapperWidth - textWidth });
    }

    renderScrollingPreviewTitle() {
        const { mockup } = this.props;
        return (
            <ul
                className={mockup.hasBackground ? styles.previewTitleInverse : styles.previewTitle}
                ref={(c) => {
                    this.wrapper = c;
                }}
                onMouseEnter={this.handleMouseOver}
                onMouseLeave={this.handleMouseLeave}
                style={{ textOverflow: this.state.textOverflow }}
            >
                <li
                    style={{ transform: `translateX(${this.state.offset}px)` }}
                    ref={(c) => {
                        this.text = c;
                    }}
                >
                    {mockup.title}
                </li>
            </ul>
        );
    }

    renderSplitPreviewTitle() {
        const { mockup } = this.props;
        const titles = mockup.previewTitle.split(',');

        const titlesList = [];
        for (const title of titles) {
            if (title && title.length > 1) {
                titlesList.push(
                    <li key={`preview-title-${mockup.id}-${title}`}>
                        <LocalisedTitle
                            title={title}
                            intlId={`mockup.previewTitles.${hashString(title)}`}
                        />
                    </li>
                );
            }
        }

        return (
            <ul className={mockup.hasBackground ? styles.listTitlesInverse : styles.listTitles}>
                {titlesList}
            </ul>
        );
    }

    render() {
        const { mockup, intl } = this.props;
        const previewTitleLength = mockup.previewTitle ? mockup.previewTitle.length : 0;
        const titleLength = mockup.title ? mockup.title.length : 0;

        if (intl.locale === 'en' && previewTitleLength < titleLength) {
            return this.renderScrollingPreviewTitle();
        }
        if (mockup.previewTitle) {
            return this.renderSplitPreviewTitle();
        }

        return null;
    }
}

MockupPreviewTitle.propTypes = {
    mockup: PropTypes.object.isRequired
};

export default injectIntl(MockupPreviewTitle);
