export const COLORS_SOLID = [
    '#FFFFFF',
    '#000000',
    '#808080',
    '#FF0000',
    '#FF4886',
    '#C30FE1',
    '#673AB7',
    '#3F51B5',
    '#0030FF',
    '#00A6FF',
    '#00CFFF',
    '#00B7A7',
    '#00A307',
    '#6ECE00',
    '#C3F238',
    '#FFE300',
    '#F1C338',
    '#FF9800',
    '#FF5622',
    '#7E4D3B',
    '#341500'
];

export const COLORS_LIGHT = [
    '#FFFFFF',
    '#000000',
    '#DCDCDC',
    '#FFB4B4',
    '#FFB4CE',
    '#F4B4FF',
    '#D5BDFF',
    '#C0C9FF',
    '#A3B4FF',
    '#B4E5FF',
    '#C8F5FF',
    '#B5FFF8',
    '#94E097',
    '#D7FFA9',
    '#F0FFC3',
    '#FFF6AF',
    '#FFEBAE',
    '#FFDCA8',
    '#FFC6B4',
    '#D7BFB6',
    '#A68D7B'
];

export const COLORS_DARK = [
    '#FFFFFF',
    '#000000',
    '#777777',
    '#A40000',
    '#A92E58',
    '#700782',
    '#3E246D',
    '#2C387D',
    '#001D9A',
    '#006296',
    '#00708A',
    '#00776D',
    '#005704',
    '#427B00',
    '#67811B',
    '#BFAB09',
    '#A68729',
    '#B16900',
    '#9E3211',
    '#523126',
    '#1B0B00'
];

export const GRADIENTS_SOLID = [
    [194, 194, 194, 1, 255, 255, 255, 1, 'linear'],
    [0, 0, 0, 1, 159, 159, 159, 1, 'linear'],
    [255, 102, 0, 1, 255, 210, 0, 1, 'linear'],
    [177, 0, 27, 1, 255, 0, 23, 1, 'linear'],
    [118, 0, 181, 1, 255, 87, 185, 1, 'linear'],
    [3, 108, 218, 1, 21, 245, 253, 1, 'linear'],
    [0, 131, 6, 1, 132, 247, 0, 1, 'linear']
];

export const GRADIENTS_LIGHT = [
    [228, 228, 228, 1, 255, 255, 255, 1, 'linear'],
    [69, 69, 69, 1, 164, 164, 164, 1, 'linear'],
    [255, 198, 161, 1, 255, 247, 210, 1, 'linear'],
    [255, 136, 154, 1, 255, 229, 231, 1, 'linear'],
    [231, 185, 255, 1, 255, 255, 255, 1, 'linear'],
    [27, 212, 255, 1, 220, 253, 255, 1, 'linear'],
    [85, 205, 96, 1, 239, 255, 146, 1, 'linear']
];

export const GRADIENTS_DARK = [
    [122, 122, 122, 1, 227, 223, 223, 1, 'linear'],
    [0, 0, 0, 1, 52, 52, 52, 1, 'linear'],
    [179, 32, 0, 1, 234, 159, 0, 1, 'linear'],
    [72, 0, 11, 1, 215, 0, 19, 1, 'linear'],
    [73, 0, 112, 1, 192, 63, 138, 1, 'linear'],
    [58, 57, 133, 1, 0, 127, 255, 1, 'linear'],
    [0, 86, 4, 1, 18, 185, 0, 1, 'linear']
];
