export function getFileExension(filePath) {
    // eslint-disable-next-line no-bitwise
    return filePath.slice(((filePath.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
}

export function getFileName(filePath) {
    const nameArray = filePath.split('/');
    const filename = nameArray[nameArray.length - 1];
    return filename.split('.')[0];
}

export const isFileSignatureValid = (validImagesMagicBytes, file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
    
        reader.onload = (e) => {
            const buffer = new Uint8Array(e.target.result);
            const arrayOfArraysOfMagicBytes = Object.values(validImagesMagicBytes)
            const isFileValid = arrayOfArraysOfMagicBytes
                .some(magicBytes => { 
                    const magicBytesAreCorrectForCurrentFileType = magicBytes
                        .every((magicByte, index) => buffer[index] === magicByte)
                    
                    return magicBytesAreCorrectForCurrentFileType
                })
            
        
            resolve(isFileValid)
        };
    
        reader.readAsArrayBuffer(file);
    })
  }

export const isMimeTypeAllowed = (allowedImages, imageType) => allowedImages.some(allowedImage => `image/${allowedImage}` === imageType || `application/${allowedImage}` === imageType)

export const isFileExtensionAllowed = (allowedImages, imageExtension) => allowedImages.some(allowedImage => allowedImage === imageExtension)
