import { createCanvas } from '@smartmockups/rendering';

/**
 * Load Image source as Canvas element
 * @param src
 * @param maxSize - max size of canvas to avoid exceeding its limit, f.e. by uploading too large resolution images
 * @returns {Promise<unknown>} - canvas containing image source
 */
const loadImageAsCanvas = (src, maxSize = 5000) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            const MAX_WIDTH = maxSize;
            const MAX_HEIGHT = maxSize;
            let width = image.width;
            let height = image.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }

            const canvas = createCanvas(width, height);
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            resolve(canvas);
        };
        image.onerror = (err) => {
            reject(err);
        };
        image.crossOrigin = 'Anonymous';
        image.src = src;
    });
};
export default loadImageAsCanvas;
