import React, {useEffect, useMemo, useReducer, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from '../MockupPreview.module.scss';
import {Blurhash} from "react-blurhash";
import { isMobileOs } from "../../../../utils/featureDetector";
import {
    getImgixParams,
    PREVIEW_HEIGHT,
    PREVIEW_WIDTH,
    SIZES_WITH_SIDEBAR
} from '../helpers';
// import Hls from "hls.js";
import isLocalisedMockupPreview from "../../../../utils/locale/isLocalisedMockupPreview";
import {PUBLIC_MOCKUP_VIDEO_IMGIX, PUBLIC_MOCKUP_IMGIX} from "../../../../utils/variables";

import VideoTimer from "../../../Base/VideoTimer/VideoTimer";
import Imgix from "react-imgix";
import { isClient } from "../../../../utils/envDetection";

const MockupVideo = (props) => {
    const { mockup, locale, isLazyLoad, isMasonry } = props;
    const imgixParams = getImgixParams(mockup.category);
    const loc = isLocalisedMockupPreview(locale) ? locale : 'en';
    const thumbId = mockup.previews.thumb;
    const player = useRef();

    const initialState = {
        isHover: false,
        isHoverPoster: false,
        duration: null,
        currentTime: null,
        videoCanPlay: false,
        videoPlay: false,
        isMobileOs: false,
        hasPreload: false
    };

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    );

    const videoUrl = `${PUBLIC_MOCKUP_VIDEO_IMGIX}/${thumbId}_${loc}.mp4?fm=mp4&res=low#t=0.001`;

    const posterUrl = isMasonry
        ? `${PUBLIC_MOCKUP_IMGIX}/${thumbId}_${loc}.jpg`
        : `${PUBLIC_MOCKUP_IMGIX}/${thumbId}_${loc}.jpg?h=${PREVIEW_HEIGHT}&w=${PREVIEW_WIDTH}`;

    const blurhasId = mockup.previews.blurhash;
    const blurhash = blurhasId && blurhasId.length > 6 ? blurhasId : 'eIP7X,%M~9%M0g_NkWay%2j]^hof58WC=_-:s:bIIpaeR.j[slkCS5';


    // TODO až budou videa přístupná na https://smartmockups-mockups-dev.imgix.video
    /*
    useEffect(() => {
        if (player.current?.canPlayType("application/vnd.apple.mpegurl")) {
            player.current.src = videoUrl;
        } else if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(videoUrl)
            hls.attachMedia(player.current);
        } else {
            player.current.src = videoUrl;
        }
    }, []);

     */

    useEffect(() => {
        if (isClient()) {
            setState({isMobileOs: isMobileOs()});
            setState({hasPreload: true});
        }
    }, []);

    useEffect(() => {
        if(state.videoCanPlay) {
            if(state.isHover) {
                player.current?.play();
                setState({videoPlay: true});
            } else {
                player.current?.pause();
                setState({videoPlay: false});
            }
        }
    }, [state.videoCanPlay, state.isHover]);

    const handleMouseHover = (isHover) => {
        setState({isHover});

        if (isHover === true) {
            setState({isHoverPoster: isHover});
        }
    }

    const handleCanPlay = () => {
        setState({videoCanPlay: true, duration: player.current?.duration ? player.current.duration : null});
    }

    const handleCurrentTime = (event) => {
        if (event && state.videoCanPlay) {
            setState({currentTime: Math.round(event.target.currentTime)});
        }
    }

    const renderPoster = () => {
        return (
            <Imgix
                className={`${styles.image}
                ${
                    isLazyLoad ? `${styles.isLazy} lazyload` : ''
                }
                ${
                    state.isHoverPoster ? styles.posterHidden : ''
                }`}
                src={`${posterUrl}`}
                sizes={SIZES_WITH_SIDEBAR}
                attributeConfig={
                    isLazyLoad && {
                        src: 'data-src',
                        srcSet: 'data-srcset',
                        loading: 'lazy'
                    }
                }
                htmlAttributes={{
                    alt: mockup.title
                }}
                height={PREVIEW_HEIGHT}
                imgixParams={imgixParams}
            />
        );
    }

    const renderVideo = () => {
        return (
            <video
                className={isLazyLoad ? `${styles.isLazy} lazyload` : ''}
                src={videoUrl}
                ref={player}
                preload={state.hasPreload ? 'metadata' : 'none'}
                loop
                muted
                height={PREVIEW_HEIGHT}
                onCanPlay={handleCanPlay}
                onTimeUpdate={event => handleCurrentTime(event)}
            />
        )
    }

    return useMemo(() => {
        return (
            <figure
                title={mockup.title}
                className={styles.ratioContainer}
                onMouseEnter={() => handleMouseHover(true)}
                onMouseLeave={() => handleMouseHover(false)}
            >
                <Blurhash
                    hash={blurhash}
                    className={`${styles.blurhash} blurhash`}
                />
                {renderPoster()}
                {!state.isMobileOs && (
                    renderVideo()
                )}

                <VideoTimer
                    isVideoPlay={state.videoPlay}
                    isEnded={state.currentTime === 0}
                    duration={state.duration}
                    type="list"
                />
            </figure>
        );

    },[mockup, videoUrl, posterUrl, imgixParams, state.videoPlay, state.currentTime]);
}

MockupVideo.propTypes = {
    mockup: PropTypes.object.isRequired,
    locale: PropTypes.string,
    isLazyLoad: PropTypes.bool,
    isMasonry: PropTypes.bool
};

export default MockupVideo;
