import { HISTORY_IMGIX } from '../utils/variables';
import { rendererService } from './ServiceClient';

import { alignImage } from '@smartmockups/rendering';
import loadImageAsCanvas from '../utils/loadImageAsCanvas';
import { loadBlobAsCanvas } from '../utils/loadBlob';
import { hexToRgba } from '../components/Editor/ColorPicker/ColorPickerHelpers';

/**
 * Create live preview of user's file exported from Canva
 * @param {String} mockupId - id of a mockup we want to render
 * @param {String} userFileId - key of a user file that we want to show on
 * @param {String} color - color of an object on a mockup
 * @returns {Object} - image in Base64 format
 * @param {String} source - source of the image. canva integration or smartmockups direct upload.
 * @param {Object} [cancelToken] - Axios cancelToken object.
 */
export function getLivePreviewApi(mockupId, userFileId, color, source) {
    // we'll allow live previews only to logged in users, so'll need to send a token
    const config = {
        responseType: 'json'
    };
    userFileId = encodeURIComponent(userFileId);
    const size = window.devicePixelRatio > 1.5 ? 'medium' : 'small';
    let url = `preview/${size}/${mockupId}/${userFileId}/${source}`;
    if (color) {
        url += `?color=${color}`;
    }
    return rendererService.get(url, config);
}


/**
 * Create live preview of client side - we allowed load image for unlogged user
 * @param {Object} mockup - object of mockup we want to render
 * @param {Object} canvas - object canvas of file input we want applied to mockup
 * @param {String} color - color of an object on a mockup
 * @returns {Object} - image in Base64 format
 */
export async function getLivePreviewClientSideApi(mockup, canvas, color) {
    if (mockup.screens) {
        const { screens, colorMaskColors } = await setLivePreviewToMockupApi(mockup, { canvas }, color);

        const params = {
            mockup,
            width: 300 * Math.min(2, window.devicePixelRatio),
            uploadedScreens: screens,
            colorMaskColors
        };

        let MockupCreator;

        if(mockup.scene) {
            MockupCreator = (await import('../rendering_old/mockupCreator')).default;
        } else {
            MockupCreator = (await import('../rendering/mockupCreator')).default;
        }

        const mockupCreator = new MockupCreator();
        const result = await mockupCreator.drawMockup(params);
        return result.toDataURL();
    }

    return {cancelled: true};
}

/**
 * Allows us to load live preview image to selected mockups as "Screens" param.
 * Creates an array of "Screen" objects that are then added to local state and used to render the mockup detail
 * @param {Object} mockup - object of mockup we want to render
 * @param {Object} file - blob object of file input we want applied to mockup
 * @param {string} fileId - id of file input we want loaded from url and applied to mockup
 * @param {Object} canvas - object canvas of file input we want applied to mockup
 * @param {String} color - color of an object on a mockup
 * @returns {Promise<*>}
 */
export async function setLivePreviewToMockupApi(mockup, { file, fileId, canvas }, color) {
    // const url = `${${fileId}?auto=format,compress`
    let image;
    if (file) {
        // file expects file blob from input and must be converted to canvas.
        image = await loadBlobAsCanvas(file);
    } else if(canvas) {
        // canvas expects canvas from input, it does not need to be further processed.
        image = canvas;
    } else {
        // fileId expects id from input and image is loaded from url which must be converted to canvas.
        const url = `${HISTORY_IMGIX}/${fileId}?auto=compress,format`;
        image = await loadImageAsCanvas(url);
    }

    const alignSize = mockup.category === 'apparel' ? '60 auto' : 'cover';

    const screens = mockup.screens.map((mockupScreen) => {
        const screen = alignImage(image, mockupScreen, 'center, center', alignSize);

        // we need to add same properties that ImageCropper is adding to the "Screen" Object
        screen.uploadedImage = image;
        screen.uploadedImageWidth = image.width;
        screen.uploadedImageHeight = image.height;
        screen.uploadedImageTitle = 'Live preview image';
        screen.timestamp = new Date().getTime();
        return screen;
    });
    let colorMaskColors = [];

    const objectColor = color ? hexToRgba(color) : [255, 255, 255, 1];

    if (mockup.colorMasks) {
        colorMaskColors = mockup.colorMasks.map(() => objectColor);
    }

    // compatibility with legacy colorMask property
    else if (mockup.colorMask) {
        colorMaskColors = objectColor;
    }

    return { screens, colorMaskColors };
}

/**
 * Allows us to load preview indexed history image to selected mockups as "Screens" param.
 * Creates an array of "Screen" objects that are then added to local state and used to render the mockup detail
 * @param {Object} mockup - object of mockup we want to render
 * @param {Array} screensSetting - array with screens setting
 * @param {Array} color - array colors of an object on a mockup
 * @param {Number} variationSetting - number of variation
 * @param {Boolean} shadowSetting - boolean for render shadow
 * @returns {Promise<*>}
 */
export async function setPreviewIndexedHistoryToMockupApi(mockup, screensSetting, color, variationSetting, shadowSetting) {

    const screens = await Promise.all(screensSetting.map(async (screen) => {
        if (screen?.uploadedImage) {
            screen.uploadedImage = await loadImageAsCanvas(screen.uploadedImage);
        }
        return screen;
    }));

    const colorMaskColors = color || [[255, 255, 255, 1]];
    const showShadows = shadowSetting || false;
    const variation = variationSetting || 0;

    return { screens, colorMaskColors, showShadows, variation };
}
