import React from 'react';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import styles from './MockupList.module.scss';
import PropTypes from "prop-types";

const MockupList = props => {

    const { isMasonry, isNoResponsive, className } = props;

    const {
        screenSM,
    } = useWindowDimensions();

    if (screenSM && isMasonry && !isNoResponsive) {
        return (
          <div data-cy="mockupList" ref={props.ref} className={`${className ?? className}`}>
              {props.children}
          </div>
        );
    }

    return (
        <div
            className={`${isMasonry ? styles.wrapperMasonry : styles.wrapper} ${className ?? className}`}
            data-cy="mockupList"
        >
            {props.children}
        </div>
    );
}

MockupList.propTypes = {
    isMasonry: PropTypes.bool,
    isNoResponsive: PropTypes.bool,
    className: PropTypes.string
};

export default MockupList;
