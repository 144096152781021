import { GRADIENTS_SOLID } from './SwatchesVariables';

export function sanitizeSelectedColor(selected) {
    //get current colors
    let color;

    //pokud je zadany nesmysl(neni to barva) nastav bilou
    if (Array.isArray(selected) && selected.length === 4) {
        color = [...selected];
    } else {
        color = [255, 255, 255, 1];
    }

    //pokud ma barva 100% pruhlednost, tak ji vypni
    if (selected[0] === 0 && selected[1] === 0 && selected[2] === 0 && selected[3] === 0) {
        color[3] = 1;
    }

    return { r: color[0], g: color[1], b: color[2], a: color[3] };
}

export function colorObjectToRgbaArray(color) {
    return [color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a];
}

export function rgbToHex(r, g, b) {
    return componentToHex(r) + componentToHex(g) + componentToHex(b);
}
function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
}
export function hexToRgba(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), 1]
        : null;
}

export function getDefaultGradient() {
    return GRADIENTS_SOLID[1];
}

export function getGradientColorsForActiveColor(color) {
    let color1;
    let color2;
    if (Array.isArray(color) && color.length === 9) {
        color1 = color.slice(0, 4);
        color2 = color.slice(4, 8);
    } else {
        const defaultGradient = getDefaultGradient();
        //render default colors
        color1 = defaultGradient.slice(0, 4);
        color2 = defaultGradient.slice(4, 8);
    }

    return {
        color1,
        color2
    };
}
