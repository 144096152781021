import loadImageAsImg from './loadImageAsImg';
import loadImageAsCanvas from './loadImageAsCanvas';

export const loadBlobAsImg = (blob) => handleBlob(blob, loadImageAsImg);
export const loadBlobAsCanvas = (blob) => handleBlob(blob, loadImageAsCanvas);

async function handleBlob(blob, handler) {
    const url = URL.createObjectURL(blob);
    try {
        const img = await handler(url);
        URL.revokeObjectURL(url);
        return img;
    } catch {
        URL.revokeObjectURL(url);
    }
}
