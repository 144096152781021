import { USER_FILE_API } from '../utils/variables';
import { createCORSRequest } from './helpers';
import serviceClient from './ServiceClient';

export async function uploadUserFileToS3(file, filetype, username, progressFunction) {
    try {
        const s3 = await getSignedUrl(filetype, username);
        await uploadBlobToS3(file, s3.mime, s3.url, progressFunction);
        return s3;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export function uploadBlobToS3(blob, filetype, s3url, progressFunction) {
    return new Promise((resolve, reject) => {
        const xhr = createCORSRequest('PUT', s3url);
        xhr.onload = () => {
            if (xhr.status === 200) {
                console.log(xhr.status);
                resolve();
            } else {
                console.log(xhr.status);
                reject(xhr);
            }
        };
        xhr.upload.onprogress = progressFunction;
        xhr.setRequestHeader('Content-Type', filetype);
        xhr.setRequestHeader('x-amz-acl', 'public-read');
        xhr.send(blob);
    });
}

export function getSignedUrl(filetype, username) {
    return serviceClient.get(`${USER_FILE_API}/upload?filetype=${filetype}&username=${username}`);
}
